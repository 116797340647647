import { Select } from "antd";
import React from "react";

const OrgSelect = ({ selectOrganisation, organisation, name, data }) => {
  if (Array.isArray(data) && data.length > 0) {
    return (
      <Select
        style={{ width: 180, marginRight: 10 }}
        onChange={selectOrganisation}
        value={organisation ? organisation : name}
        //   defaultValue={name}
      >
        {data?.map((org, elem) => {
          return <Select.Option value={org?.id}>{org?.name}</Select.Option>;
        })}
      </Select>
    );
  } else return null;
};

export default OrgSelect;
