import { Table, Space } from "antd";
import "antd/dist/antd.css";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { GetHeaders } from "../helpers/getHeaders.js";
import { useState, useEffect } from "react";
import { Menu, Dropdown, Button, Checkbox, Input, Select } from "antd";
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import styles from "../styles/order.module.scss";
import OrgSelect from "./OrgSelect.js";

export default function OrderList(props) {
  const { Option } = Select;
  let menu;
  let statesMenu;
  let sourceStateMenu = <Menu></Menu>;
  let sourceDistrictMenu = <Menu></Menu>;
  let tableData;
  let timer;
  let limit = 8;
  const [organisation, setOrganisation] = useState("");
  const [isOrder, setIsOrder] = useState(true);
  const [sourceState, setSourceState] = useState("");
  const [sourceDistrict, setSourceDistrict] = useState("");
  const [sourceLocationList, setSourceLocationList] = useState([]);
  const [selectedSourceState, setSelectedSourceState] = useState("");
  const [selectedSourceDistrict, setSelectedSourceDistrict] = useState("");
  const [states, setStates] = useState([]);
  const [displayStates, setDisplayStates] = useState([]);
  const [offset, setOffset] = useState(0);

  const GET_MY_ORGANISATIONS = gql`
    query GetMyOrganisations {
      getMyOrganisations {
        id
        name
        code
        namespaceId
      }
    }
  `;
  const GET_ORGANISATION_ORDERS = gql`
    query GetOrganisationOrders(
      $limit: Int!
      $offset: Int
      $sourceState: String
      $sourceDistrict: String
      $states: [String!]
    ) {
      getOrganisationOrders(
        limit: $limit
        offset: $offset
        sourceState: $sourceState
        sourceDistrict: $sourceDistrict
        states: $states
      ) {
        ID
        Items {
          ID
          createdAt
          Description
          Quantity
          ClosedQuantity
          QuantityUnit
          UnitPrice
          TotalAmount
          DiscountAmount
          Currency
          Metadata
          ItemMetadata {
            ListingImages {
              url
            }
          }
          ProductImage
          ProductRef
          ProductName
          ProductSnapshotID
          ProductSnapshot {
            ID
            Data {
              product_name
            }
          }
          StateID
          State {
            ID
            TenantID
            Label
            Stage
            Type
            IsSystem
            createdAt
          }
          StateMachineID
          OrderID
        }
        createdAt
        TenantID
        TotalAmount
        DiscountAmount
        Currency
        BuyerID
        SellerID
        ShippingAddressID
        BillingInfoID
        StateID
        StateMachineID
        ConfigID
        BillingInfo {
          ID
          BillingAddressID
          Gstn
          Pan
        }
        State {
          ID
          TenantID
          Stage
          Label
          Type
          IsSystem
          createdAt
        }
        Config
        StateMachine
        Price
        Metadata {
          DiscountCode
          BidReference
          SellerShippingAddressID
          SellerBillingAddressID
          ListingReference
          SourceLocation {
            Pincode
            District
            State
          }
          OrganisationID
        }
        SellerShippingAddress {
          id
          ownerRef
          address
          area
          pincode
          country
          label
          location {
            lat
            long
          }
        }
        SellerAccountDetails {
          id
          name
        }
      }
    }
  `;
  const GET_STATE_DISTRICT_LIST = gql`
    query Query($state: String, $district: String) {
      getStateDistrictList(state: $state, district: $district) {
        pincode
        state
        district
      }
    }
  `;
  const { loading, error, data, refetch } = useQuery(GET_MY_ORGANISATIONS, {
    context: {
      headers: GetHeaders({
        token: `bearer ${props.value.token}`,
        namespace: props.value.namespace,
      }),
    },
    fetchPolicy: "cache-and-network",
  });
  const [
    getStateDistrictList,
    { loading: locationLoading, error: locationError, data: locationData },
  ] = useLazyQuery(GET_STATE_DISTRICT_LIST, {
    context: {
      headers: GetHeaders({
        token: `bearer ${props.value.token}`,
      }),
    },
  });

  useEffect(() => {
    setOrganisation("");
  }, [props.value.namespace]);
  const [
    getOrders,
    { called, loading: orderLoading, data: orderData, refetch: orderRefetch },
  ] = useLazyQuery(GET_ORGANISATION_ORDERS, {
    fetchPolicy: "no-cache",
    context: {
      headers: GetHeaders({
        token: `bearer ${props.value.token}`,
        namespace: props.value.namespace,
        organisationId: organisation,
      }),
    },
  });
  useEffect(() => {
    if (data) {
      setOrganisation(
        organisation ? organisation : data?.getMyOrganisations[0]?.id
      );
      getOrders({
        variables: { limit: limit, offset: offset },
      });
      setIsOrder(true);
      props.value.setShowDetails(false);
    }
  }, [data]);
  useEffect(() => {
    getOrders({
      variables: {
        limit: limit,
        offset: offset,
        sourceState: selectedSourceState,
        sourceDistrict: selectedSourceDistrict,
        ...(states.length != 0 ? { states } : null),
      },
    });
  }, [props.value.showDetails]);

  const onApplyFilters = () => {
    setOffset(0);
    setDisplayStates(states);
    getOrders({
      variables: {
        limit: limit,
        offset: 0,
        sourceState: selectedSourceState,
        sourceDistrict: selectedSourceDistrict,
        ...(states.length != 0 ? { states } : null),
      },
    });
  };
  const onPageChange = (type) => {
    if (type == "next") {
      setOffset((offset) => offset + limit);
      getOrders({
        variables: {
          limit: limit,
          offset: offset + limit,
          sourceState: selectedSourceState,
          sourceDistrict: selectedSourceDistrict,
          ...(states.length != 0 ? { states } : null),
        },
      });
    } else {
      setOffset((offset) => offset - limit);
      getOrders({
        variables: {
          limit: limit,
          offset: offset - limit,
          sourceState: selectedSourceState,
          sourceDistrict: selectedSourceDistrict,
          ...(states.length != 0 ? { states } : null),
        },
      });
    }
  };
  const selectState = (e, item) => {
    if (e.target.checked === true) {
      setStates([...states, item.Stage]);
    } else {
      let arr = states;
      let index = arr.indexOf(item.Stage);
      if (index > -1) {
        arr.splice(index, 1);
        setStates(arr);
      }
    }
  };
  const getLocationList = (e, type) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fetchData();
    }, 500);
    const fetchData = async () => {
      clearTimeout(timer);
      if (type == "state") {
        setSourceState(e);
        let response = await getStateDistrictList({
          variables: { state: e, district: sourceDistrict },
        });
        if (response.data) {
          setSourceLocationList(response.data.getStateDistrictList);
        }
      } else {
        setSourceDistrict(e);
        let response = await getStateDistrictList({
          variables: { state: sourceState, district: e },
        });
        if (response.data) {
          setSourceLocationList(response.data.getStateDistrictList);
        }
      }
    };
  };
  if (sourceLocationList.length != 0) {
    let arr = [...new Set(sourceLocationList.map((item) => item.state))];
    sourceStateMenu = (
      <Menu>
        {arr.map((item) => {
          return (
            <Menu.Item key={item} onClick={() => setSelectedSourceState(item)}>
              {item}
            </Menu.Item>
          );
        })}
      </Menu>
    );
    sourceDistrictMenu = (
      <Menu>
        {sourceLocationList.map((item) => {
          return (
            <Menu.Item
              key={`${item.state}+${item.district}`}
              onClick={() => setSelectedSourceDistrict(item.district)}
            >
              {item.district}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  } else {
    sourceStateMenu = <Menu></Menu>;
    sourceDistrictMenu = <Menu></Menu>;
  }

  if (props.value.states) {
    statesMenu = (
      <Menu>
        {props.value.states.getOrderStates?.map((item) => {
          return (
            <Menu.Item key={item.ID}>
              <Checkbox onChange={(e) => selectState(e, item)}>
                {item.Label !== '' ? item.Label: item.Stage}
              </Checkbox>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  } else {
    statesMenu = <Menu></Menu>;
  }
  if (orderData) {
    tableData = orderData.getOrganisationOrders?.map((item) => {
      return {
        key: item.ID,
        id: item.ID,
        skuName: item.Items[0].ProductName,
        address: item.Metadata?.SourceLocation,
        unitPrice: item.Items[0]?.UnitPrice,
        quantity: item.Items[0].Quantity,
        totalAmount: item.TotalAmount,
        state: item.Items[0].State.Label,
        action: item,
      };
    });
  }
  const tableColumns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "SkuName",
      dataIndex: "skuName",
      key: "skuName",
    },
    {
      title: "Address",
      key: "address",
      render: (item) => (
        <p>
          {item.address?.District}, {item.address?.State} -{" "}
          {item.address?.Pincode}{" "}
        </p>
      ),
    },
    {
      title: "UnitPrice",
      dataIndex: "unitPrice",
      key: "unitPrice",
      render: (item) => <p>{Number(item).toFixed(1)} Rs</p>,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (item) => <p>{Number(item).toFixed(1)} Kg</p>,
    },
    {
      title: "TotalAmount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (item) => <p>{Number(item).toFixed(1)} Rs</p>,
    },
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "Action",
      key: "action",
      render: (item) => (
        <a
          onClick={() => {
            props.value.setShowDetails(true);
            props.value.setSelectedOrder(item.action);
            props.value.setIsOrderUpdated(false);
          }}
        >
          <div>View</div>
        </a>
      ),
    },
  ];
  return (
    <div id={styles.container}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        {/* <Dropdown overlay={menu} placement="bottomRight" arrow> */}
        {organisation ? (
          <OrgSelect
            selectOrganisation={(e) => setOrganisation(e)}
            organisation={organisation}
            name={data?.getMyOrganisations[0]?.name}
            data={organisation ? data?.getMyOrganisations : ""}
          />
        ) : (
          <Button shape="round" type="primary" danger>
            Select Organisation
          </Button>
        )}
        {/* </Dropdown> */}
      </div>
      <Input.Group compact>
        <Dropdown overlay={statesMenu} placement="bottomRight" arrow>
          <Button shape="round" style={{ color: "grey" }}>
            <span style={{ marginRight: "30px" }}>Status</span> &#8744;
          </Button>
        </Dropdown>
        <Select
          notFoundContent="Search State"
          style={{ minWidth: "200px" }}
          allowClear={true}
          onClear={() => setSourceState("")}
          showSearch
          placeholder="Select a State"
          optionFilterProp="children"
          onSearch={(e) => getLocationList(e, "state")}
          onChange={(e) => setSelectedSourceState(e)}
        >
          {[...new Set(sourceLocationList.map((item) => item.state))].map(
            (item, index) => {
              return (
                <Option key={`${index}-${item}`} value={item}>
                  {item}
                </Option>
              );
            }
          )}
        </Select>
        <Select
          notFoundContent="Search District"
          style={{ minWidth: "200px" }}
          allowClear={true}
          onClear={() => setSourceDistrict("")}
          showSearch
          placeholder="Select a District"
          optionFilterProp="children"
          onSearch={(e) => getLocationList(e, "district")}
          onChange={(e) => setSelectedSourceDistrict(e)}
        >
          {sourceLocationList.map((item, index) => {
            return (
              <Option key={`${index}-${item.district}`} value={item.district}>
                {item.district}
              </Option>
            );
          })}
        </Select>
        <Button
          style={{ marginRight: "10px" }}
          onClick={onApplyFilters}
          shape="round"
          danger
        >
          Apply Filters
        </Button>

        {displayStates.length != 0 ? (
          <p
            style={{
              borderRadius: "10px",
              border: "1px solid lightgrey",
              padding: "4px",
              color: "grey",
            }}
          >
            Status Filter :-{" "}
            {displayStates.map((item, index) => {
              if (index == displayStates.length - 1) {
                return item;
              } else {
                return item + ", ";
              }
            })}
          </p>
        ) : null}
      </Input.Group>
      <div style={{ overflow: "scroll", marginTop: "20px" }}>
        <Table
          loading={orderLoading}
          columns={tableColumns}
          dataSource={isOrder == true ? tableData : null}
          pagination={false}
        />
        <br />
        {tableData ? (
          <Space size="large">
            {offset != 0 || tableData?.length == 0 ? (
              <div>
                <Button
                  onClick={() => onApplyFilters()}
                  style={{
                    color: "skyblue",
                    fontSize: "17px",
                    padding: "6px 20px 20px 20px",
                    height: "40px",
                    border: "1px solid lightblue",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  shape="round"
                >
                  &lt;&lt; Top
                </Button>
                <DoubleLeftOutlined
                  onClick={() => onPageChange("prev")}
                  style={{
                    color: "skyblue",
                    fontSize: "17px",
                    padding: "10px",
                    border: "1px solid lightblue",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                />
              </div>
            ) : null}
            <Button
              style={{
                cursor: "auto",
                borderRadius: "70%",
                padding: "3px 10px 10px 10px",
                fontSize: "17px",
                color: "grey",
                border: "1px solid lightgrey",
              }}
              shape="round"
              danger
            >
              {offset / limit + 1}
            </Button>
            {tableData?.length >= limit ? (
              <DoubleRightOutlined
                onClick={() => onPageChange("next")}
                style={{
                  color: "skyblue",
                  fontSize: "17px",
                  padding: "10px",
                  border: "1px solid lightblue",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              />
            ) : null}
          </Space>
        ) : null}
      </div>
    </div>
  );
}
